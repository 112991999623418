import { FunctionComponent } from "react";
import styled from "styled-components";
import { Container, ContainerProps, Icon, IconProps } from ".";

interface TitleProps {
    className?: string;
    container?: ContainerProps;
    left?: IconProps | undefined;
    label?: ContainerProps | undefined;
    right?: IconProps | undefined;
}

export const Title: FunctionComponent<TitleProps> = styled(({ className, container, left, label, right }: TitleProps): JSX.Element => {
    return (
        <Container display={{ direction: "row" }} {...container} className={className}>
            {left?.icon ? <Icon {...left} className={className + " left"} /> : null}
            {label?.children ? <Container {...label} className={className + " label"} /> : null}
            {right?.icon ? <Icon {...right} className={className + " left"} /> : null}
        </Container>
    );
})``;

export default Title;
