import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import Menu from "./components/Menu";
import { Container, NotificationType } from "./components/interface";
import Navigation from "./components/Navigation";
import { Notification } from "./components/Notification";
import Theme from "./wrapper/Theme";
import { About, Contact, Intro, Projects, Timeline } from "./components";

export type ContextType = {
    activeTheme?: string;
    setActiveTheme: (theme: string) => void;
    setNotification: (notifications: NotificationType) => void;
    scrollPosition: number;
};

export const Context = React.createContext<ContextType>({} as ContextType);

interface AppProps {
    className?: string;
}

export const App: FunctionComponent<AppProps> = styled(({ className }: AppProps): JSX.Element => {
    const [notification, setNotification] = useState<NotificationType>({ child: null, timer: 0 });
    const [activeTheme, setActiveTheme] = useState<string>("");
    const [scrollPosition, setScrollPosition] = useState<number>(0);

    useEffect(() => {
        if (localStorage.getItem("theme")) setActiveTheme(localStorage.getItem("theme") || "");
        else setActiveTheme("dark");
    }, []);

    useEffect(() => {
        if (activeTheme === "") return;
        localStorage.setItem("theme", activeTheme);
    }, [activeTheme]);

    const handleScroll = () => {
        setScrollPosition(document.getElementById("scroll")?.scrollTop || 0);
    };

    return (
        <div className={className}>
            {activeTheme !== "" && (
                <Theme className="theme" activeTheme={activeTheme}>
                    <Context.Provider value={{ activeTheme: activeTheme, setActiveTheme: setActiveTheme, setNotification: setNotification, scrollPosition: scrollPosition }}>
                        <Container display={{ justify: "center", direction: null, position: "absolute", index: "9" }} size={{ width: "100vw" }}>
                            <Navigation className="navigation" scrollPosition={scrollPosition} />
                            <Notification className="notification" notification={notification} />
                            <Menu className="menu" />
                        </Container>
                        <Container
                            className="scrollable"
                            display={{ id: "scroll", position: "fixed", direction: "column", align: "center", overflow: { x: "hidden", y: "auto" } }}
                            size={{ width: "84vw", height: "100vh", padding: "0px 5vw 0px 11vw" }}
                            mouse={{ onScroll: handleScroll }}
                        >
                            <Intro />
                            <About id="about" />
                            <Timeline id="timeline" />
                            <Projects id="projects" />
                            <Contact id="contact" />
                        </Container>
                    </Context.Provider>
                </Theme>
            )}
        </div>
    );
})`
    .scrollable {
        @media screen and (max-width: 800px) {
            padding: 0 8vw;
        }

        &::-webkit-scrollbar {
            z-index: 9;
            width: 14px;
        }

        &::-webkit-scrollbar-track {
            background-color: var(--c01);
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--c28);
            border: 4px solid var(--c01);
            border-radius: 10px;
            width: 18px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: var(--c29);
        }
    }
`;

export default App;
