import { FunctionComponent, ReactNode } from "react";
import styled from "styled-components";
import { BorderProps, ColorProps, DisplayProps, FontProps, ModifyProps, MouseProps, SizeProps } from "./Typing";

export interface ContainerProps {
    className?: string;
    children?: ReactNode;
    display?: DisplayProps;
    displayHover?: DisplayProps;
    font?: FontProps;
    fontHover?: FontProps;
    size?: SizeProps;
    sizeHover?: SizeProps;
    color?: ColorProps;
    colorHover?: ColorProps;
    border?: BorderProps;
    borderHover?: BorderProps;
    mouse?: MouseProps;
    modify?: ModifyProps;
    modifyHover?: ModifyProps;
}

export const Container: FunctionComponent<ContainerProps> = styled(({ className, children, display, mouse }: ContainerProps): JSX.Element => {
    return (
        <div className={className} id={display?.id} onScroll={mouse?.onScroll} onClick={mouse?.onClick} onMouseEnter={mouse?.onMouseEnter} onMouseLeave={mouse?.onMouseLeave}>
            {children}
        </div>
    );
})`
    display: ${props => props.display?.display || (props.display?.direction || props.display?.gap || props.display?.justify || props.display?.align || props.display?.grow ? "flex" : null)};
    position: ${props => (typeof props.display?.position === "object" ? props.display?.position.position : props.display?.position)};
    top: ${props => typeof props.display?.position === "object" && props.display?.position.right};
    right: ${props => typeof props.display?.position === "object" && props.display?.position.top};
    bottom: ${props => typeof props.display?.position === "object" && props.display?.position.bottom};
    left: ${props => typeof props.display?.position === "object" && props.display?.position.left};
    flex-direction: ${props => props.display?.direction || (props.display?.direction !== null && "column")};
    flex-wrap: ${props => props.display?.wrap};
    flex-grow: ${props => props.display?.grow};
    justify-content: ${props => props.display?.justify};
    align-items: ${props => props.display?.align};
    align-self: ${props => props.display?.self};
    gap: ${props => props.display?.gap};
    z-index: ${props => props.display?.index};
    overflow: ${props => typeof props.display?.overflow === "string" && props.display?.overflow};
    overflow-x: ${props => typeof props.display?.overflow === "object" && props.display?.overflow?.x};
    overflow-y: ${props => typeof props.display?.overflow === "object" && props.display?.overflow?.y};
    min-width: ${props => typeof props.size?.width === "object" && props.size?.width?.min};
    width: ${props => (typeof props.size?.width === "object" ? props.size?.width?.width : props.size?.width)};
    max-width: ${props => typeof props.size?.width === "object" && props.size?.width?.max};
    min-height: ${props => typeof props.size?.height === "object" && props.size?.height?.min};
    height: ${props => (typeof props.size?.height === "object" ? props.size?.height?.height : props.size?.height)};
    max-height: ${props => typeof props.size?.height === "object" && props.size?.height?.max};
    margin: ${props => props.size?.margin};
    padding: ${props => props.size?.padding};
    border-radius: ${props => props.border?.radius || "4px"};
    border: ${props => (typeof props.border?.border !== "string" ? null : props.border?.border || (props.border?.color && "1px solid"))};
    border-top: ${props => typeof props.border?.border === "object" && props.border?.border.top};
    border-right: ${props => typeof props.border?.border === "object" && props.border?.border.right};
    border-bottom: ${props => typeof props.border?.border === "object" && props.border?.border.bottom};
    border-left: ${props => typeof props.border?.border === "object" && props.border?.border.left};
    border-color: ${props => (props.border?.color && "var(--" + props.border?.color + ")") || "var(--c00)"};
    color: ${props => props.color?.color && "var(--" + props.color?.color + ")"};
    background-color: ${props => props.color?.backgroundColor && "var(--" + props.color?.backgroundColor + ")"};
    background: ${props => props.color?.background};
    filter: ${props => props.color?.filter};
    transition: ${props => props.modify?.transition};
    transform: ${props => props.modify?.transform};
    cursor: ${props => (props.mouse?.onClick ? "pointer" : (typeof props.children === "string" && "text") || props.mouse?.cursor)};
    white-space: ${props => props.font?.whiteSpace};
    text-align: ${props => props.font?.align};
    font-family: ${props => props.font?.family && "var(--" + props.font?.family + ")"};
    font-style: ${props => props.font?.style};
    font-weight: ${props => props.font?.weight};
    font-size: ${props => !props.font?.clamp && props.font?.size};
    font-size: ${props => (props.font?.clamp ? "clamp(" + props.font?.clamp.min + ", " + props.font?.size + ", " + props.font?.clamp.max + ")" : props.font?.size)};
    text-orientation: ${props => props.font?.orientation};
    writing-mode: ${props => props.font?.writingMode};

    &:hover {
        display: ${props => props.displayHover?.display};
        position: ${props => (typeof props.displayHover?.position === "object" ? props.displayHover?.position.position : props.displayHover?.position)};
        top: ${props => typeof props.displayHover?.position === "object" && props.displayHover?.position.right};
        right: ${props => typeof props.displayHover?.position === "object" && props.displayHover?.position.top};
        bottom: ${props => typeof props.displayHover?.position === "object" && props.displayHover?.position.bottom};
        left: ${props => typeof props.displayHover?.position === "object" && props.displayHover?.position.left};
        flex-direction: ${props => props.displayHover?.direction};
        flex-wrap: ${props => (typeof props.displayHover?.wrap === "boolean" ? "wrap" : props.displayHover?.wrap)};
        flex-grow: ${props => (typeof props.displayHover?.grow === "boolean" ? "1" : props.displayHover?.grow)};
        justify-content: ${props => props.displayHover?.justify};
        align-items: ${props => props.displayHover?.align};
        align-self: ${props => props.displayHover?.self};
        gap: ${props => props.displayHover?.gap};
        z-index: ${props => props.displayHover?.index};
        overflow: ${props => typeof props.displayHover?.overflow === "string" && props.displayHover?.overflow};
        overflow-x: ${props => typeof props.displayHover?.overflow === "object" && props.displayHover?.overflow?.x};
        overflow-y: ${props => typeof props.displayHover?.overflow === "object" && props.displayHover?.overflow?.y};
        min-width: ${props => typeof props.sizeHover?.width === "object" && props.sizeHover?.width?.min};
        width: ${props => (typeof props.sizeHover?.width === "object" ? props.sizeHover?.width?.width : props.sizeHover?.width)};
        max-width: ${props => typeof props.sizeHover?.width === "object" && props.sizeHover?.width?.max};
        min-height: ${props => typeof props.sizeHover?.height === "object" && props.sizeHover?.height?.min};
        height: ${props => (typeof props.sizeHover?.height === "object" ? props.sizeHover?.height?.height : props.sizeHover?.height)};
        max-height: ${props => typeof props.sizeHover?.height === "object" && props.sizeHover?.height?.max};
        margin: ${props => props.sizeHover?.margin};
        padding: ${props => props.sizeHover?.padding};
        border-radius: ${props => props.borderHover?.radius};
        border: ${props => (typeof props.borderHover?.border !== "string" ? null : props.borderHover?.border || (props.borderHover?.color && "1px solid"))};
        border-top: ${props => typeof props.borderHover?.border === "object" && props.borderHover?.border.top};
        border-right: ${props => typeof props.borderHover?.border === "object" && props.borderHover?.border.right};
        border-bottom: ${props => typeof props.borderHover?.border === "object" && props.borderHover?.border.bottom};
        border-left: ${props => typeof props.borderHover?.border === "object" && props.borderHover?.border.left};
        border-color: ${props => props.borderHover?.color && "var(--" + props.borderHover?.color + ")"};
        color: ${props => props.colorHover?.color && "var(--" + props.colorHover?.color + ")"};
        background-color: ${props => props.colorHover?.backgroundColor && "var(--" + props.colorHover?.backgroundColor + ")"};
        background: ${props => props.colorHover?.background};
        filter: ${props => props.colorHover?.filter};
        transition: ${props => props.modifyHover?.transition};
        transform: ${props => props.modifyHover?.transform};
        white-space: ${props => props.fontHover?.whiteSpace};
        text-align: ${props => props.fontHover?.align};
        font-family: ${props => props.fontHover?.family && "var(--" + props.fontHover?.family + ")"};
        font-style: ${props => props.fontHover?.style};
        font-weight: ${props => props.fontHover?.weight};
        font-size: ${props => !props.fontHover?.clamp && props.fontHover?.size};
        font-size: ${props => (props.fontHover?.clamp ? "clamp(" + props.fontHover?.clamp.min + ", " + props.fontHover?.size + ", " + props.fontHover?.clamp.max + ")" : props.fontHover?.size)};
        text-orientation: ${props => props.fontHover?.orientation};
        writing-mode: ${props => props.fontHover?.writingMode};
    }
`;

export default Container;
