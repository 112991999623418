import styled from "styled-components";
import { IconDefinition } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BorderProps, ColorProps, DisplayProps, ModifyProps, MouseProps, SizeProps } from ".";
import { FunctionComponent } from "react";

export interface IconProps {
    className?: string;
    icon: IconDefinition;
    iconHover?: IconDefinition;
    display?: DisplayProps;
    displayHover?: DisplayProps;
    size?: SizeProps;
    sizeHover?: SizeProps;
    color?: ColorProps;
    colorHover?: ColorProps;
    border?: BorderProps;
    borderHover?: BorderProps;
    mouse?: MouseProps;
    modify?: ModifyProps;
    modifyHover?: ModifyProps;
}

export const Icon: FunctionComponent<IconProps> = styled(({ className, icon, mouse }: IconProps): JSX.Element => {
    return <FontAwesomeIcon className={className} icon={icon} onClick={mouse?.onClick} onMouseEnter={mouse?.onMouseEnter} onMouseLeave={mouse?.onMouseLeave} />;
})`
    display: ${props => props.display?.display};
    flex-direction: ${props => props.display?.direction};
    align-items: ${props => props.display?.align};
    gap: ${props => props.display?.gap};
    z-index: ${props => props.display?.index};
    min-width: ${props => typeof props.size?.width === "object" && props.size?.width?.min};
    width: ${props => (typeof props.size?.width === "object" ? props.size?.width?.width : props.size?.width)};
    max-width: ${props => typeof props.size?.width === "object" && props.size?.width?.max};
    min-height: ${props => typeof props.size?.height === "object" && props.size?.height?.min};
    height: ${props => (typeof props.size?.height === "object" ? props.size?.height?.height : props.size?.height)};
    max-height: ${props => typeof props.size?.height === "object" && props.size?.height?.max};
    margin: ${props => props.size?.margin};
    padding: ${props => props.size?.padding};
    border-radius: ${props => props.border?.radius || "4px"};
    border: ${props => props.border?.border || (props.border?.color && "1px solid")};
    border-top: ${props => typeof props.border?.border === "object" && props.border?.border.top};
    border-right: ${props => typeof props.border?.border === "object" && props.border?.border.right};
    border-bottom: ${props => typeof props.border?.border === "object" && props.border?.border.bottom};
    border-left: ${props => typeof props.border?.border === "object" && props.border?.border.left};
    border-color: ${props => (props.border?.color && "var(--" + props.border?.color + ")") || "var(--c00)"};
    color: ${props => props.color?.color && "var(--" + props.color?.color + ")"};
    background-color: ${props => props.color?.backgroundColor && "var(--" + props.color?.backgroundColor + ")"};
    filter: ${props => props.color?.filter};
    transition: ${props => props.modify?.transition};
    transform: ${props => props.modify?.transform};
    cursor: ${props => (props.mouse?.onClick ? "pointer" : props.mouse?.cursor)};

    &:hover {
        display: ${props => props.displayHover?.display};
        flex-direction: ${props => props.displayHover?.direction};
        align-items: ${props => props.displayHover?.align};
        gap: ${props => props.displayHover?.gap};
        z-index: ${props => props.displayHover?.index};
        min-width: ${props => typeof props.sizeHover?.width === "object" && props.sizeHover?.width?.min};
        width: ${props => (typeof props.sizeHover?.width === "object" ? props.sizeHover?.width?.width : props.sizeHover?.width)};
        max-width: ${props => typeof props.sizeHover?.width === "object" && props.sizeHover?.width?.max};
        min-height: ${props => typeof props.sizeHover?.height === "object" && props.sizeHover?.height?.min};
        height: ${props => (typeof props.sizeHover?.height === "object" ? props.sizeHover?.height?.height : props.sizeHover?.height)};
        max-height: ${props => typeof props.sizeHover?.height === "object" && props.sizeHover?.height?.max};
        margin: ${props => props.sizeHover?.margin};
        padding: ${props => props.sizeHover?.padding};
        border-radius: ${props => props.borderHover?.radius};
        border: ${props => props.borderHover?.border || (props.borderHover?.color && "1px solid")};
        border-top: ${props => typeof props.borderHover?.border === "object" && props.borderHover?.border.top};
        border-right: ${props => typeof props.borderHover?.border === "object" && props.borderHover?.border.right};
        border-bottom: ${props => typeof props.borderHover?.border === "object" && props.borderHover?.border.bottom};
        border-left: ${props => typeof props.borderHover?.border === "object" && props.borderHover?.border.left};
        border-color: ${props => props.borderHover?.color && "var(--" + props.borderHover?.color + ")"};
        color: ${props => props.colorHover?.color && "var(--" + props.colorHover?.color + ")"};
        background-color: ${props => props.colorHover?.backgroundColor && "var(--" + props.colorHover?.backgroundColor + ")"};
        filter: ${props => props.colorHover?.filter};
        transition: ${props => props.modifyHover?.transition};
        transform: ${props => props.modifyHover?.transform};
        blend
    }
`;

export default Icon;
