import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FunctionComponent } from "react";
import styled from "styled-components";
import { Container, Title } from "./interface";

export interface ContactProps {
    className?: string;
    id?: string;
}

export const Contact: FunctionComponent<ContactProps> = styled(({ className, id }: ContactProps): JSX.Element => {
    return (
        <Container className={className} display={{ id: id, align: "center", gap: "10px" }} size={{ width: { max: "400px" }, padding: "25vh 0px 45vh 0px" }}>
            <Container
                children={
                    <Container
                        display={{ direction: "row", gap: "0.6em" }}
                        color={{ color: "c27" }}
                        font={{ family: "f01", weight: "600", size: "1.6vw", clamp: { min: "1rem", max: "1.65rem" }, whiteSpace: "nowrap" }}
                    >
                        <Container children="04." color={{ color: "c29" }} border={{ border: "none" }} />
                        <Container children="What's Next?" color={{ color: "c27" }} border={{ border: "none" }} font={{ weight: "400" }} />
                    </Container>
                }
                border={{ border: { bottom: "1px solid" }, radius: "0px", color: "c03" }}
                size={{ width: "fit-content", padding: "0.2em 2vw" }}
            />

            <Container
                children="My inbox is open, feel free to contact me, and I'll try my best to get back to you quickly!"
                font={{ align: "center", family: "f01", size: "1.2vw", clamp: { min: "0.65rem", max: "0.9rem" } }}
                size={{ width: "100%", padding: "10px 0px" }}
            />
            <Title
                container={{
                    display: { direction: "row", align: "center" },
                    color: { color: "c01" },
                    colorHover: { color: "c28" },
                    size: { width: "fit-content", margin: "0px 0px 0px -22px" },
                    modify: { transition: "color 0.5s" },
                }}
                left={{
                    icon: faCaretRight,
                    size: { height: "13px", padding: "0px 0.5rem" },
                    mouse: { cursor: "pointer" },
                }}
                label={{
                    children: "Say Hi",
                    font: { family: "f01", size: "1.2vw", clamp: { min: "1rem", max: "1.25rem" }, whiteSpace: "nowrap" },
                    color: { color: "c29" },
                    size: { width: "fit-content" },
                    mouse: { onClick: () => window.open("mailto:jyri.kurtakko@gmail.com") },
                }}
            />
        </Container>
    );
})`
    animation: fadeIn 0.8s ease-in;
`;

export default Contact;
