import { FunctionComponent } from "react";
import styled from "styled-components";
import { Container, ContainerProps } from ".";
import { ObjectProps, SizeProps } from "./Typing";

export interface ImageProps {
    className?: string;
    object: ObjectProps;
    objectHover?: ObjectProps;
    size?: SizeProps;
    sizeHover?: SizeProps;
    container?: ContainerProps;
}

export const Image: FunctionComponent<ImageProps> = styled(({ className, object, container }: ImageProps): JSX.Element => {
    return (
        <Container {...container}>
            <img className={className} src={object.source} alt={object.alt} />
        </Container>
    );
})`
    object-fit: ${props => props.object?.fit};
    object-position: ${props => props.object?.position};
    min-width: ${props => typeof props.size?.width === "object" && props.size?.width?.min};
    width: ${props => (typeof props.size?.width === "object" ? props.size?.width?.width : props.size?.width || "100%")};
    max-width: ${props => typeof props.size?.width === "object" && props.size?.width?.max};
    min-height: ${props => typeof props.size?.height === "object" && props.size?.height?.min};
    height: ${props => (typeof props.size?.height === "object" ? props.size?.height?.height : props.size?.height || "100%")};
    max-height: ${props => typeof props.size?.height === "object" && props.size?.height?.max};
    margin: ${props => props.size?.margin};
    padding: ${props => props.size?.padding};
    border-radius: ${props => (props.container?.border && props.container?.border?.radius) || "3px"};

    &:hover {
        object-fit: ${props => props.objectHover?.fit};
        object-position: ${props => props.objectHover?.position};
        min-width: ${props => typeof props.sizeHover?.width === "object" && props.sizeHover?.width?.min};
        width: ${props => (typeof props.sizeHover?.width === "object" ? props.sizeHover?.width?.width : props.sizeHover?.width)};
        max-width: ${props => typeof props.sizeHover?.width === "object" && props.sizeHover?.width?.max};
        min-height: ${props => typeof props.sizeHover?.height === "object" && props.sizeHover?.height?.min};
        height: ${props => (typeof props.sizeHover?.height === "object" ? props.sizeHover?.height?.height : props.sizeHover?.height)};
        max-height: ${props => typeof props.sizeHover?.height === "object" && props.sizeHover?.height?.max};
        margin: ${props => props.sizeHover?.margin};
        padding: ${props => props.sizeHover?.padding};
    }
`;

export default Image;
