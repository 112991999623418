import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FunctionComponent } from "react";
import styled from "styled-components";
import { Container, Title } from "./interface";

export interface NavigationProps {
    className?: string;
    scrollPosition?: number;
}

export const Navigation: FunctionComponent<NavigationProps> = styled(({ className, scrollPosition }: NavigationProps): JSX.Element => {
    const handleScrollTo = (element: string) => {
        if (element === "projects") document.getElementById(element)?.scrollIntoView({ behavior: "smooth", block: "start" });
        else document.getElementById(element)?.scrollIntoView({ behavior: "smooth", block: "center" });
    };

    const handleInView = (id: string) => {
        const current = Math.floor(window.innerHeight / 2) + (scrollPosition || 0);
        const element = document.getElementById(id);
        if ((element?.offsetTop || 0) >= current) return ["c28", "400"];
        else if ((element?.offsetTop || 0) + (element?.offsetHeight || 0) <= current) return ["c28", "400"];
        else return ["c29", "600"];
    };

    const handleMenuItem = (label: string, scroll: string): JSX.Element => {
        return (
            <Title
                container={{
                    display: { direction: "row", align: "center" },
                    colorHover: { color: "c29" },
                }}
                left={{
                    icon: faCaretRight,
                    size: {
                        height: "12px",
                        padding: "0 0.5rem",
                    },
                }}
                label={{
                    children: label,
                    mouse: { onClick: () => handleScrollTo(scroll) },
                    color: { color: handleInView(scroll)[0] },
                    size: { margin: "0px 14px 0px 0px" },
                    font: { family: "f01", size: "1.2vw", weight: handleInView(scroll)[1], clamp: { min: "0.64rem", max: "1rem" }, align: "center" },
                }}
            />
        );
    };

    return (
        <Container
            className={className}
            display={{ position: "fixed", direction: "row", justify: "flex-end", gap: "1em" }}
            color={{ color: "c01", backgroundColor: "c11" }}
            border={{ border: { bottom: "1px solid" }, radius: "0px", color: "c03" }}
            size={{ width: { width: "calc(100vw - 30px)", max: "1400px" }, height: scrollPosition === 0 ? "58px" : "28px", margin: "0px 20px 0px 10px" }}
            modify={{ transition: "height 0.3s" }}
        >
            {handleMenuItem("01. About", "about")}
            {handleMenuItem("02. Timeline", "timeline")}
            {handleMenuItem("03. Projects", "projects")}
            {handleMenuItem("04. Contact", "contact")}
        </Container>
    );
})`
    animation: SlideDown 0.5s ease-in;

    @keyframes SlideDown {
        from {
            transform: translateY(-59px);
        }
        to {
            transform: translateY(0px);
        }
    }

    @media screen and (max-width: 800px) {
        justify-content: center;
        gap: 0.5em;
    }
`;

export default Navigation;
