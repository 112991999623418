import { FunctionComponent } from "react";
import styled from "styled-components";
import { Container, ContainerProps } from ".";
export interface ProgressProps {
    className?: string;
    container?: ContainerProps;
    progress?: { progress: number; color: string; backgroundColor: string };
}

export const Progress: FunctionComponent<ProgressProps> = styled(({ className, container }: ProgressProps): JSX.Element => {
    return (
        <Container {...container} className={className}>
            <div className="pie" />
        </Container>
    );
})`
    .pie {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: ${props =>
            "conic-gradient(var(--" + props.progress?.color + ") " + props.progress?.progress + "%, var(--" + props.progress?.backgroundColor + ") " + props.progress?.progress + "%)"};
    }
`;

export default Progress;
