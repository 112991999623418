import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FunctionComponent } from "react";
import styled from "styled-components";
import { Container, Image, Title } from "./interface";

export interface AboutProps {
    className?: string;
    id?: string;
}

export const About: FunctionComponent<AboutProps> = styled(({ className, id }: AboutProps): JSX.Element => {
    return (
        <Container className={className} display={{ id: id, gap: "10px" }} size={{ width: { max: "900px" }, padding: "25vh 0px" }}>
            <Container
                children={
                    <Container
                        display={{ direction: "row", gap: "0.6em" }}
                        color={{ color: "c27" }}
                        font={{ family: "f01", weight: "600", size: "1.6vw", clamp: { min: "1rem", max: "1.65rem" }, whiteSpace: "nowrap" }}
                    >
                        <Container children="01." color={{ color: "c29" }} border={{ border: "none" }} />
                        <Container children="About Me" color={{ color: "c27" }} border={{ border: "none" }} font={{ weight: "400" }} />
                    </Container>
                }
                border={{ border: { bottom: "1px solid" }, radius: "0px", color: "c03" }}
                size={{ width: "80%" }}
            />

            <Container display={{ direction: "row", wrap: "wrap", justify: "center", gap: "10px" }}>
                <Container display={{ grow: "1", gap: "0.5em" }} size={{ width: { min: "300px", width: "60%" } }}>
                    <Container
                        children="My interest in development started in the mid-2000s when I decided I wanted one of my favorite games at the time (Diablo 2) to have gamepad support. After a weekend of tinkering, I had something workable on my hands and a newly found glimpse into what makes computers tick - It turns out that programming was way more rewarding than I ever thought."
                        font={{ family: "f01", size: "1.2vw", clamp: { min: "0.65rem", max: "0.9rem" } }}
                    />
                    <Container
                        children="Fast-forwarding to today, I had an opportunity to make the switch and turn my long-time hobby into a profession. I graduated in just under two years and got hired as a software dev during my first year. These days I mainly focus on front-end and UX design at Virtasoft Oy."
                        font={{ family: "f01", size: "1.2vw", clamp: { min: "0.65rem", max: "0.9rem" } }}
                    />
                    <Container
                        children="I'm also very familiar with both Linux & Windows workstations and servers, having worked as a system admin."
                        font={{ family: "f01", size: "1.2vw", clamp: { min: "0.65rem", max: "0.9rem" } }}
                    />
                    <Container children="Here are a few technologies I’ve been working with recently:" font={{ family: "f01", size: "1.2vw", clamp: { min: "0.65rem", max: "0.9rem" } }} />

                    <Container display={{ direction: "row" }}>
                        <Container size={{ width: "100%" }}>
                            <Title
                                left={{ icon: faCaretRight, size: { height: "12px", margin: "auto 10px auto 0" }, color: { color: "c29" } }}
                                label={{
                                    children: "TypeScript",
                                    size: { width: "fit-content" },
                                    color: { color: "c27" },
                                    font: { family: "f01", size: "1.2vw", clamp: { min: "0.65rem", max: "0.9rem" } },
                                }}
                            />
                            <Title
                                left={{ icon: faCaretRight, size: { height: "12px", margin: "auto 10px auto 0" }, color: { color: "c29" } }}
                                label={{
                                    children: "React",
                                    size: { width: "fit-content" },
                                    color: { color: "c27" },
                                    font: { family: "f01", size: "1.2vw", clamp: { min: "0.65rem", max: "0.9rem" } },
                                }}
                            />
                        </Container>

                        <Container size={{ width: "100%" }}>
                            <Title
                                left={{ icon: faCaretRight, size: { height: "12px", margin: "auto 10px auto 0" }, color: { color: "c29" } }}
                                label={{
                                    children: "Node.js",
                                    size: { width: "fit-content" },
                                    color: { color: "c27" },
                                    font: { family: "f01", size: "1.2vw", clamp: { min: "0.65rem", max: "0.9rem" } },
                                }}
                            />
                            <Title
                                left={{ icon: faCaretRight, size: { height: "12px", margin: "auto 10px auto 0" }, color: { color: "c29" } }}
                                label={{
                                    children: "Firebase",
                                    size: { width: "fit-content" },
                                    color: { color: "c27" },
                                    font: { family: "f01", size: "1.2vw", clamp: { min: "0.65rem", max: "0.9rem" } },
                                }}
                            />
                        </Container>

                        <Container size={{ width: "100%" }}>
                            <Title
                                left={{ icon: faCaretRight, size: { height: "12px", margin: "auto 10px auto 0" }, color: { color: "c29" } }}
                                label={{
                                    children: ".Express",
                                    size: { width: "fit-content" },
                                    color: { color: "c27" },
                                    font: { family: "f01", size: "1.2vw", clamp: { min: "0.65rem", max: "0.9rem" } },
                                }}
                            />
                            <Title
                                left={{ icon: faCaretRight, size: { height: "12px", margin: "auto 10px auto 0" }, color: { color: "c29" } }}
                                label={{
                                    children: ".NET core",
                                    size: { width: "fit-content" },
                                    color: { color: "c27" },
                                    font: { family: "f01", size: "1.2vw", clamp: { min: "0.65rem", max: "0.9rem" } },
                                }}
                            />
                        </Container>
                    </Container>
                </Container>
                <Image
                    object={{
                        source: "headshot.png",
                        fit: "cover",
                    }}
                    container={{
                        display: { grow: "1" },
                        modify: { transition: "border 0.2s" },
                        border: { border: "1px solid", color: "c02" },
                        borderHover: { border: "1px solid", color: "c29" },
                        size: { width: { width: "35%", max: "300px" }, height: { max: "300px" } },
                    }}
                />
            </Container>
        </Container>
    );
})`
    animation: fadeIn 0.8s ease-in;
`;

export default About;
