import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FunctionComponent, useState } from "react";
import styled from "styled-components";
import { Container, Title } from "./interface";

export interface TimelineProps {
    className?: string;
    id?: string;
}

export const Timeline: FunctionComponent<TimelineProps> = styled(({ className, id }: TimelineProps): JSX.Element => {
    const [activeMenu, setActiveMenu] = useState<string>("Virtasoft");

    const handleTimeline = (title: string, company: { name: string; url?: string }, timelines: string[], texts: string[]): JSX.Element => {
        return (
            <Container display={{ gap: "0.5em" }} size={{ width: "70%" }}>
                <Container>
                    <Container children={title} color={{ color: "c27" }} font={{ family: "f01", size: "1.2vw", clamp: { min: "0.9rem", max: "1.125rem" }, weight: "600" }} />
                    <Container
                        children={company.name}
                        color={{ color: "c29" }}
                        font={{ family: "f01", size: "1vw", clamp: { min: "0.9rem", max: "1.125rem" } }}
                        mouse={{ onClick: company.url ? () => window.open(company.url) : undefined }}
                    />
                    {timelines.map(timeline => (
                        <Container children={timeline} color={{ color: "c27" }} font={{ family: "f01", size: "1.2vw", clamp: { min: "0.65rem", max: "0.9rem" } }} />
                    ))}
                </Container>

                {texts.map(text => (
                    <Title
                        key={text}
                        left={{
                            icon: faCaretRight,
                            size: { height: "12px", margin: "0.2em 10px 0px 0px" },
                            color: { color: "c29" },
                        }}
                        label={{
                            children: text,
                            size: { width: "100%" },
                            font: { family: "f01", size: "1.2vw", clamp: { min: "0.65rem", max: "0.9rem" } },
                        }}
                    />
                ))}
            </Container>
        );
    };

    const handleMenuItem = (name: string): JSX.Element => {
        return (
            <Container
                children={name}
                display={{ justify: "center" }}
                border={{ border: { left: "1px solid" }, radius: "0px 4px 4px 0px", color: activeMenu === name ? "c29" : "c03" }}
                color={{ color: activeMenu === name ? "c29" : "c28", backgroundColor: "c01" }}
                colorHover={{ color: "c27", backgroundColor: "c29" }}
                size={{ height: "26px", padding: "0.8em 0.8em" }}
                font={{ size: "1.2vw", clamp: { min: "0.75rem", max: "1rem" }, weight: activeMenu === name ? "600" : "400" }}
                mouse={{ onClick: () => setActiveMenu(name) }}
                modify={{ transition: "color 0.2s" }}
            />
        );
    };

    return (
        <Container
            className={className}
            display={{ id: id, justify: "flex-start", align: "flex-start", gap: "10px" }}
            size={{ width: { width: "100%", max: "700px" }, height: { min: "150px" }, padding: "25vh 0px" }}
        >
            <Container
                children={
                    <Container
                        display={{ direction: "row", gap: "0.6em" }}
                        color={{ color: "c27" }}
                        font={{ family: "f01", weight: "600", size: "1.6vw", clamp: { min: "1rem", max: "1.65rem" }, whiteSpace: "nowrap" }}
                    >
                        <Container children="02." color={{ color: "c29" }} border={{ border: "none" }} />
                        <Container children="My Timeline" color={{ color: "c27" }} border={{ border: "none" }} font={{ weight: "400" }} />
                    </Container>
                }
                border={{ border: { bottom: "1px solid" }, radius: "0px", color: "c03" }}
                size={{ width: "80%" }}
            />

            <Container display={{ direction: "row", gap: "10px" }} size={{ width: "100%" }}>
                <Container display={{ direction: "row" }} size={{ width: "30%" }}>
                    <Container>
                        {handleMenuItem("Virtasoft")}
                        {handleMenuItem("Lhkk")}
                        {handleMenuItem("Vesi-Lammela")}
                        {handleMenuItem("Various")}
                    </Container>
                </Container>
                {activeMenu === "Virtasoft" &&
                    handleTimeline(
                        "Software developer",
                        { name: "@ Virtasoft", url: "https://www.virtasoft.fi" },
                        ["May 2021 - July 2021 (Internship)", "August 2021 - Present"],
                        [
                            "Write modern and performant code for internal projects.",
                            "Work with a variety of different languages and frameworks, such as Html, CSS, TypeScript, React, Node.js, Express, etc.",
                            "Design, write and maintaining an internal UI framework to fit multiple different projects.",
                        ]
                    )}
                {activeMenu === "Lhkk" &&
                    handleTimeline(
                        "Student",
                        { name: "@ Lhkk", url: "https://www.lhkk.fi/" },
                        ["September 2020 - June 2022"],
                        ["Graduated with a degree in programming (ICT) from LHKK, with an excellent weighted average of 4,97."]
                    )}
                {activeMenu === "Vesi-Lammela" &&
                    handleTimeline(
                        "Technician / System Admin",
                        { name: "@ Vesi-Lammela", url: "https://www.vesi-lammela.fi/" },
                        ["August 2014 - September 2020"],
                        [
                            "Set up and maintain internal server infrastructure.",
                            "Maintain internal network and user access for private and public projects.",
                            "Set up and maintain workstations and other IT infrastructure.",
                            "Various jobs related to HVACR systems.",
                        ]
                    )}
                {activeMenu === "Various" &&
                    handleTimeline(
                        "Technician",
                        { name: "@ Various" },
                        ["Early 2004 - August 2014"],
                        ["Work as a technician or a supervisor for a few companies, such as STX-Finland and Metso Outotech, to name a couple."]
                    )}
            </Container>
        </Container>
    );
})`
    animation: fadeIn 0.8s ease-in;
`;

export default Timeline;
