import { faGithub, faGooglePlay, IconDefinition } from "@fortawesome/free-brands-svg-icons";
import { faCaretRight, faGlobeEurope } from "@fortawesome/free-solid-svg-icons";
import { FunctionComponent } from "react";
import styled from "styled-components";
import { Container, Icon, Image, Title } from "./interface";

export interface ProjectsProps {
    className?: string;
    id?: string;
}

export const Projects: FunctionComponent<ProjectsProps> = styled(({ className, id }: ProjectsProps): JSX.Element => {
    const handleProject = (
        title: string,
        name: string,
        company: { name: string; url: string },
        image: string,
        texts: string[],
        technologies: string[],
        link?: { icon: IconDefinition; url: string },
        reverse?: boolean
    ): JSX.Element => {
        return (
            <Container
                display={{ direction: !reverse ? "row" : "row-reverse", wrap: "wrap", self: !reverse ? "flex-start" : "flex-end" }}
                size={{ width: { width: "100%", max: "800px" }, margin: "30px 0px" }}
            >
                <Image
                    object={{
                        source: image,
                        position: "top",
                        fit: "cover",
                    }}
                    container={{
                        display: { grow: "1" },
                        modify: { transition: "filter 0.2s" },
                        color: { filter: "opacity(0.8) grayscale(1)", backgroundColor: "c29" },
                        colorHover: { filter: "none" },
                        border: { border: "1px solid" },
                        size: { width: { min: "300px", width: "30%" }, height: { height: "30vw", max: "300px" }, margin: "0px -10px" },
                    }}
                />

                <Container display={{ wrap: "wrap", grow: "1", index: "2" }} size={{ width: { min: "300px", width: "45%" }, margin: "0px -10px" }}>
                    <Container display={{ direction: !reverse ? "row" : "row-reverse" }} size={{ margin: "5px 20px" }}>
                        {link && (
                            <Icon
                                icon={link.icon}
                                size={{ height: "auto", width: { min: "22px", width: "1.6vw", max: "30px" }, margin: "0 10px" }}
                                colorHover={{ color: "c29" }}
                                mouse={{ onClick: () => window.open(link.url) }}
                                modify={{ transition: "color 0.2s" }}
                            />
                        )}
                        <Container display={{ align: !reverse ? "flex-end" : "flex-start" }} size={{ width: "100%" }}>
                            <Container display={{ direction: "row" }} font={{ family: "f01", size: "0.8vw", clamp: { min: "0.65rem", max: "0.9rem" } }}>
                                <Title
                                    container={{ size: { width: "fit-content", padding: "0 4px" } }}
                                    label={{
                                        children: title || "Project",
                                        color: { color: "c29" },
                                        size: { width: "fit-content" },
                                    }}
                                />
                                {company.name !== "" && (
                                    <Title
                                        container={{ size: { width: "fit-content", padding: "0 4px" } }}
                                        label={{
                                            children: "@",
                                            color: { color: "c27" },
                                            size: { width: "fit-content" },
                                        }}
                                    />
                                )}
                                {company.name !== "" && (
                                    <Title
                                        container={{ size: { width: "fit-content", padding: "0 4px" } }}
                                        label={{
                                            children: company.name || null,
                                            color: { color: "c29" },
                                            size: { width: "fit-content" },
                                            mouse: { onClick: () => window.open(company.url) },
                                        }}
                                    />
                                )}
                            </Container>
                            <Title
                                container={{ size: { width: "fit-content", padding: "0 4px" } }}
                                label={{
                                    children: name || null,
                                    color: { color: "c27" },
                                    size: { width: "fit-content" },
                                    font: { family: "f01", size: "1vw", clamp: { min: "0.8rem", max: "1.225rem" } },
                                }}
                            />
                        </Container>
                    </Container>

                    <Container
                        className="text"
                        display={{ justify: "center", gap: "0.5em" }}
                        color={{ color: "c01", backgroundColor: "c28" }}
                        border={{ color: "c01", border: "1px solid" }}
                        size={{ height: { min: "8em" }, padding: "1rem" }}
                        font={{ family: "f01", size: "1.2vw", clamp: { min: "0.65rem", max: "0.9rem" }, align: !reverse ? "end" : "start" }}
                    >
                        {texts.map(text => (
                            <Title
                                key={text}
                                container={{ size: { width: "fit-content", padding: "0 4px", margin: !reverse ? "0px 0px 0px auto" : "0px" } }}
                                label={{
                                    children: text,
                                }}
                            />
                        ))}
                    </Container>

                    <Container display={{ direction: "row", justify: !reverse ? "flex-end" : "flex-start", wrap: "wrap" }} size={{ margin: "5px 10px" }}>
                        {technologies.map(technology => (
                            <Title
                                key={technology}
                                container={{ size: { width: "fit-content", padding: "0 4px" } }}
                                left={{ icon: faCaretRight, size: { height: "12px", margin: "auto 10px auto 0px" }, color: { color: "c29" } }}
                                label={{
                                    children: technology,
                                    size: { width: "fit-content" },
                                    color: { color: "c27" },
                                    font: { family: "f01", size: "1.2vw", clamp: { min: "0.65rem", max: "0.9rem" } },
                                }}
                            />
                        ))}
                    </Container>
                </Container>
            </Container>
        );
    };

    const handleSmallProject = (title: string, name: string, texts: string[], technologies: string[], link?: { icon: IconDefinition; url: string }): JSX.Element => {
        return (
            <Container display={{ grow: "1" }} size={{ width: { min: "300px", width: "45%" }, margin: "0px -10px" }} border={{ border: "1px solid", color: "c03" }} color={{ color: "c28" }}>
                <Container display={{ direction: "row", align: "center" }} size={{ margin: "5px 20px" }}>
                    <Container display={{ align: "flex-start" }} size={{ width: "100%" }}>
                        <Title
                            container={{ size: { width: "fit-content", padding: "0px 4px" } }}
                            label={{
                                children: title || "Project",
                                color: { color: "c29" },
                                size: { width: "fit-content" },
                                font: { family: "f01", size: "0.8vw", clamp: { min: "0.65rem", max: "0.9rem" } },
                            }}
                        />
                        <Title
                            container={{ size: { width: "fit-content", padding: "0px 4px" } }}
                            label={{
                                children: name || null,
                                color: { color: "c27" },
                                size: { width: "fit-content" },
                                font: { family: "f01", size: "1vw", clamp: { min: "0.8rem", max: "1.225rem" } },
                            }}
                        />
                    </Container>
                    {link && (
                        <Icon
                            icon={link.icon}
                            size={{ height: "auto", width: { min: "22px", width: "1.6vw", max: "30px" }, padding: "0px 4px" }}
                            colorHover={{ color: "c29" }}
                            mouse={{ onClick: () => window.open(link.url) }}
                            modify={{ transition: "color 0.2s" }}
                        />
                    )}
                </Container>

                <Container
                    display={{ justify: "center" }}
                    color={{ color: "c01", backgroundColor: "c28" }}
                    font={{ family: "f01", size: "1vw", clamp: { min: "0.65rem", max: "0.9rem" }, align: "center" }}
                    size={{ height: "100px", padding: "10px", margin: "0px 10px" }}
                >
                    {texts.map(text => (
                        <Title
                            key={text}
                            container={{ size: { width: "fit-content", padding: "0 4px", margin: "0 0 0 auto" } }}
                            label={{
                                children: text,
                            }}
                        />
                    ))}
                </Container>

                <Container display={{ direction: "row" }} size={{ margin: "5px 20px" }}>
                    {technologies.map(technology => (
                        <Title
                            key={technology}
                            container={{ size: { width: "fit-content", padding: "0 4px" } }}
                            left={{ icon: faCaretRight, size: { height: "12px", margin: "auto 10px auto 0px" }, color: { color: "c29" } }}
                            label={{
                                children: technology,
                                size: { width: "fit-content" },
                                color: { color: "c27" },
                                font: { family: "f01", size: "1.2vw", clamp: { min: "0.65rem", max: "0.9rem" } },
                            }}
                        />
                    ))}
                </Container>
            </Container>
        );
    };

    return (
        <Container className={className} display={{ id: id, justify: "center", align: "center" }} size={{ width: { width: "calc(100% - 20px)", max: "900px" }, padding: "25vh 0px" }}>
            <Container
                children={
                    <Container
                        display={{ direction: "row", gap: "0.6em" }}
                        color={{ color: "c27" }}
                        font={{ family: "f01", weight: "600", size: "1.6vw", clamp: { min: "1rem", max: "1.65rem" }, whiteSpace: "nowrap" }}
                    >
                        <Container children="03." color={{ color: "c29" }} border={{ border: "none" }} />
                        <Container children="Few projects" color={{ color: "c27" }} border={{ border: "none" }} font={{ weight: "400" }} />
                    </Container>
                }
                border={{ border: { bottom: "1px solid" }, radius: "0px", color: "c03" }}
                size={{ width: "80%" }}
            />
            {handleProject("Work", "Pim", { name: "Virtasoft", url: "https://www.virtasoft.fi/" }, "pim.png", ["Currently in development."], ["React", "Typescript", "Chart.js", "Node.js", "Express"])}
            {handleProject(
                "Work",
                "Oma Tiedote",
                { name: "Virtasoft", url: "https://www.virtasoft.fi/" },
                "omatiedote.png",
                [
                    "In-house service for information management and communication in housing companies.",
                    "I jumped in at the early stages of development for this project. I did most of the UI/UX work, as well as the vast majority of the front-end coding.",
                ],
                ["React", "Typescript", "i18next", "Firebase"],
                {
                    icon: faGlobeEurope,
                    url: "https://omatiedote.fi/",
                },
                true
            )}
            {handleProject(
                "Project",
                "Dice Caddy",
                { name: "", url: "" },
                "dicecaddy.png",
                [
                    "First dive into the Android ecosystem.",
                    "The goal of this weekend project was to get my feet wet with developing for Android and more importantly, to learn the flow of publishing and monetizing in Google Play Store.",
                ],
                ["Android Studio", "Java"],
                { icon: faGooglePlay, url: "https://play.google.com/store/apps/details?id=com.cakenes.dicecaddy" }
            )}
            {handleProject(
                "Assignment",
                "Srs",
                { name: "", url: "" },
                "srs.png",
                [
                    "Srs is a web app for the Leitner system (card-based spaced repetition), built in C# & HTML for a school assignment.",
                    "The project is currently not hosted, but the sources are available on GitHub.",
                ],
                ["Blazor", ".NET core"],
                { icon: faGithub, url: "https://github.com/cakenes/srs" },
                true
            )}
            {handleProject("Project", "Personal website", { name: "", url: "" }, "website.png", ["Coming soon."], ["React", "Typescript"])}
            <Container display={{ direction: "row", wrap: "wrap", gap: "60px" }} size={{ width: "100%", margin: "30px 0px" }}>
                {handleSmallProject("Config", "Script", ["Collection of shell scripts to make daily driving i3 more user-friendly."], ["Bash", "Python"], {
                    icon: faGithub,
                    url: "https://github.com/cakenes/scripts",
                })}
                {handleSmallProject("Config", "Dotfiles", ["Dotfiles for my personal Linux setups at home (i3, Polybar, Rofi etc.), devices are seperated by Branch"], ["Bash"], {
                    icon: faGithub,
                    url: "https://github.com/cakenes/dotfiles",
                })}
            </Container>
        </Container>
    );
})`
    animation: fadeIn 0.8s ease-in;
`;

export default Projects;
