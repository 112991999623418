import React, { FunctionComponent } from "react";
import styled from "styled-components";

interface PropTypes {
    className?: string;
    activeTheme: string;
    children?: React.ReactNode;
}

export const Theme: FunctionComponent<PropTypes> = styled(({ className, activeTheme, children }: PropTypes): JSX.Element => {
    return (
        <div className={className}>
            <div className={"background " + activeTheme} />
            <div className={"theme " + activeTheme}>{children}</div>
        </div>
    );
})`
    > .background {
        position: fixed;
        width: 100vw;
        height: 100vh;

        &.dark {
            background-image: url(background.svg);
            filter: opacity(0.01);
        }

        &.light {
            background-image: url(background.svg);
            filter: invert(1) opacity(0.02);
        }
    }

    > .theme {
        min-height: 100vh;

        --c00: #00000000; // Transparent
        --f01: monospace;
        --f02: "Open Sans", sans-serif;

        color: var(--c28);
        background-color: var(--c01);
        font-family: var(--f01);
        font-size: 16px;

        &.dark {
            --c01: #282c34;
            --c02: #000000;
            --c03: #505661;
            --c11: #282c34f0;
            --c27: #ccd6f6;
            --c28: #a0adc3;
            --c29: #0e78ab;
            --c30: #0eab90;
            --c31: #eeaeca;
            --c32: #ff004f;
        }

        &.light {
            --c01: #f7f9fb;
            --c02: #000000;
            --c03: #b2b5ba;
            --c11: #f7f9fbf0;
            --c27: #080809;
            --c28: #282c34;
            --c29: #0e78ab;
            --c30: #0eab90;
            --c31: #eeaeca;
            --c32: #ff004f;
        }
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
`;

export default Theme;
