import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { NotificationType, Title } from "./interface";

interface NotificationProps {
    className?: string;
    notification: NotificationType;
}

export const Notification: FunctionComponent<NotificationProps> = styled(({ className, notification }: NotificationProps): JSX.Element => {
    const [notifications, setNotifications] = useState<{ index: number; value: NotificationType }[]>([]);
    const notificationParent = useRef(null);

    const nextAvailable = useCallback(() => {
        for (let index = 0; index <= 20; index++) {
            if (!notifications || notifications.findIndex(x => x.index === index) === -1) return index;
        }
        return -1;
    }, [notifications]);

    const handleMouseOver = (index: number) => {
        const updated = notifications.slice();
        updated[index].value.current = -1;
        setNotifications(updated);
    };

    const handleMouseLeave = (index: number) => {
        const updated = notifications.slice();
        updated[index].value.current = 0;
        setNotifications(updated);
    };

    const handleRemove = (index: number) => {
        const updated = notifications.filter(filter => filter.index !== index);
        setNotifications(updated);
    };

    useEffect(() => {
        if (notification.timer === 0) return;
        setNotifications(n => [...n, { index: nextAvailable(), value: notification }]);
        // eslint-disable-next-line
    }, [notification]);

    useEffect(() => {
        const timer = setInterval(() => {
            if (notifications.length === 0) return;
            const updatedNotifications: { index: number; value: NotificationType }[] = [];
            notifications.forEach(notification => {
                if ((notification.value.current || 0) >= notification.value.timer) return;
                else if (notification.value.current === -1) updatedNotifications.push({ ...notification });
                else updatedNotifications.push({ ...notification, value: { ...notification.value, current: (notification.value.current || 0) + 100 } });
            });

            setNotifications(updatedNotifications);
        }, 100);
        return () => clearInterval(timer);
    }, [notifications]);

    return (
        <div className={className} ref={notificationParent}>
            {notifications?.map((notification, index) => {
                return (
                    <div key={notification.index} style={{ position: "fixed", bottom: notification.index * 40 + 50 + "px", margin: "auto" }}>
                        {notification.value.custom ? (
                            notification.value.child
                        ) : (
                            <Title
                                container={{
                                    color: { color: "c01", backgroundColor: "c28" },
                                    border: { border: "1px solid", color: notification.value.current === -1 ? "c32" : "c01" },
                                    size: { width: { min: "200px", width: "fit-content", max: "100vw" }, padding: "4px" },
                                    mouse: { onMouseEnter: () => handleMouseOver(index), onMouseLeave: () => handleMouseLeave(index) },
                                }}
                                label={{
                                    children: notification.value.child,
                                    color: { color: "c01" },
                                    size: { padding: "5px 5px 5px 10px" },
                                    font: { size: "1vw", clamp: { min: "0.65rem", max: "1rem" } },
                                }}
                                right={{
                                    icon: faTimesCircle,
                                    display: { index: "999" },
                                    size: { height: "14px", padding: "5px", margin: "auto 0 auto auto" },
                                    colorHover: { color: "c32" },
                                    mouse: { onClick: () => handleRemove(notification.index) },
                                }}
                            />
                        )}
                    </div>
                );
            })}
        </div>
    );
})`
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    z-index: 999;
    align-items: center;
    width: 100vw;
`;
