import { FunctionComponent } from "react";
import styled from "styled-components";
import { Container } from "./interface";

export interface IntroProps {
    className?: string;
    id?: string;
}

export const Intro: FunctionComponent<IntroProps> = styled(({ className, id }: IntroProps): JSX.Element => {
    return (
        <Container className={className} display={{ id: id, justify: "center", align: "flex-start" }} size={{ width: { max: "800px" }, padding: "40vh 0px 25vh 0px" }}>
            <Container display={{ direction: "column", wrap: "wrap", gap: "0.5em" }}>
                <Container
                    className="intro"
                    children="Hi, my name is"
                    font={{ family: "f01", size: "1.2vw", clamp: { min: "0.75rem", max: "1.125rem" }, whiteSpace: "nowrap" }}
                    size={{ margin: "0px 2em" }}
                />
                <Container
                    className="background-clip"
                    children="Jyri Kurtakko,"
                    size={{ margin: "-0.4em 0px -0.2em 0px" }}
                    font={{ family: "f01", size: "3.5vw", clamp: { min: "1.25rem", max: "4rem" }, weight: "1000", whiteSpace: "nowrap" }}
                />
                <Container
                    className="background-clip"
                    children="I create digital things."
                    size={{ margin: "-0.4em 0px 0px 0px" }}
                    font={{ family: "f01", size: "3.5vw", clamp: { min: "1.25rem", max: "4rem" }, weight: "1000", whiteSpace: "nowrap" }}
                />
            </Container>
            <Container
                children="I'm a software developer who builds (and often designs) digital things. Currently, I'm focused on creating an integration platform for electronic commerce professionals at Virtasoft Oy."
                font={{ family: "f01", size: "1.2vw", clamp: { min: "0.65rem", max: "0.9rem" } }}
            />
        </Container>
    );
})`
    animation: fadeIn 0.8s ease-in;

    .background-clip {
        animation: moveBackground 15s ease infinite;
        background: linear-gradient(45deg, var(--c30), var(--c29));
        background-size: 400% 400%;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    @keyframes moveBackground {
        0% {
            background-position: 0% 0%;
        }
        25% {
            background-position: 100% 0%;
        }
        50% {
            background-position: 100% 100%;
        }
        75% {
            background-position: 0% 100%;
        }
        100% {
            background-position: 0% 0%;
        }
    }
`;

export default Intro;
