import { faGithub, IconDefinition } from "@fortawesome/free-brands-svg-icons";
import { faClipboard, faIdBadge } from "@fortawesome/free-regular-svg-icons";
import { faEnvelope, faGlobeEurope, faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import styled from "styled-components";
import { Context, ContextType } from "../App";
import { Container, Title } from "./interface";

export interface MenuProps {
    className?: string;
}

export const Menu: React.FunctionComponent<MenuProps> = styled(({ className }: MenuProps): JSX.Element => {
    const context = useContext<ContextType>(Context);

    const verticalTitle = (leftIcon: IconDefinition, onClick: () => void, label?: string, clipboard?: boolean, notification?: string) => {
        return (
            <Title
                container={{
                    display: { direction: "row", align: "center", overflow: "hidden" },
                    color: { color: "c28", backgroundColor: "c11" },
                    colorHover: !label ? { color: "c29" } : undefined,
                    size: { width: "36px", height: "36px" },
                    sizeHover: { width: "100%" },
                    modify: { transition: "width 0.3s" },
                }}
                left={{
                    icon: leftIcon,
                    size: { height: "21px", width: { min: "21px" }, padding: "6px" },
                    mouse: !label ? { onClick: onClick } : undefined,
                }}
                label={{
                    children: label,
                    font: { size: "1vw", clamp: { min: "0.75rem", max: "1rem" }, whiteSpace: "nowrap" },
                    color: { color: "c28" },
                    colorHover: { color: "c29" },
                    size: { padding: "0 4px" },
                    mouse: { onClick: onClick },
                    modify: { transition: "color 0.2s" },
                }}
                right={
                    clipboard
                        ? {
                              icon: faClipboard,
                              size: { height: "13px", padding: "6px" },
                              color: { color: "c28" },
                              colorHover: { color: "c29" },
                              mouse: { onClick: () => handleClipboard(label || "", notification) },
                              modify: { transition: "color 0.2s" },
                          }
                        : undefined
                }
            />
        );
    };

    const horizontalTitle = (leftIcon: IconDefinition, onClick: () => void, label?: string, clipboard?: boolean, notification?: string) => {
        return (
            <Title
                container={{
                    display: { direction: "column-reverse", align: "center", overflow: "hidden" },
                    color: { color: "c28", backgroundColor: "c11" },
                    colorHover: !label ? { color: "c29" } : undefined,
                    size: { width: "36px", height: "36px" },
                    sizeHover: { height: "100%" },
                    modify: { transition: "height 0.3s" },
                }}
                left={{
                    icon: leftIcon,
                    size: { height: "21px", padding: "6px" },
                }}
                label={
                    label
                        ? {
                              children: label,
                              font: { size: "1vw", clamp: { min: "0.75rem", max: "1rem" }, writingMode: "vertical-lr", whiteSpace: "nowrap" },
                              color: { color: "c28" },
                              colorHover: { color: "c29" },
                              size: { padding: "4px 0px" },
                              mouse: { onClick: onClick },
                              modify: { transition: "color 0.2s" },
                          }
                        : undefined
                }
                right={
                    clipboard
                        ? {
                              icon: faClipboard,
                              size: { height: "13px", padding: "6px" },
                              colorHover: { color: "c29" },
                              mouse: { onClick: () => handleClipboard(label || "", notification) },
                              modify: { transition: "color 0.2s" },
                          }
                        : undefined
                }
            />
        );
    };

    const handleClipboard = (input: string, notification?: string) => {
        navigator.clipboard.writeText(input);
        context.setNotification({ child: notification || "Url copied to clipboard", timer: 1000 });
    };

    return (
        <Container className={className} size={{ width: { width: "100vw", max: "1400px" } }}>
            <Container className="vertical" display={{ direction: "row", position: { position: "fixed", bottom: "0px" } }} size={{ margin: "0px 0px 0px 50px" }}>
                <Container size={{ height: "300px", width: "1px" }} color={{ backgroundColor: "c03" }} />
                <Container display={{ gap: "20px" }} size={{ margin: "0px 0px 0px -17px" }}>
                    {verticalTitle(faIdBadge, () => window.open("resume.pdf"), "Resume")}
                    {verticalTitle(faEnvelope, () => window.open("mailto:jyri.kurtakko@gmail.com"), "jyri.kurtakko@gmail.com", true, "Email copied to clipboard")}
                    {verticalTitle(faGlobeEurope, () => window.open("https://cakenes.com"), "https://cakenes.com", true)}
                    {verticalTitle(faGithub, () => window.open("https://github.com/cakenes"), "https://github.com/cakenes", true)}
                    {verticalTitle(
                        context.activeTheme === "dark" ? faSun : faMoon,
                        () => context.setActiveTheme(context.activeTheme === "dark" ? "light" : "dark"),
                        context.activeTheme === "dark" ? "Light theme" : "Dark theme"
                    )}
                </Container>
            </Container>

            <Container className="horizontal" display={{ align: "center", position: { position: "fixed", bottom: "0px" } }} size={{ width: "calc(100vw - 20px)", margin: "0px 20px 0px 0px" }}>
                <Container display={{ index: "1", direction: "row", gap: "20px", align: "flex-end" }}>
                    {horizontalTitle(faIdBadge, () => window.open("resume.pdf"), "Resume", false)}
                    {horizontalTitle(faEnvelope, () => window.open("mailto:jyri.kurtakko@gmail.com"), "jyri.kurtakko@gmail.com", true, "Email copied to clipboard")}
                    {horizontalTitle(faGlobeEurope, () => window.open("https://cakenes.com"), "https://cakenes.com", true)}
                    {horizontalTitle(faGithub, () => window.open("https://github.com/cakenes"), "https://github.com/cakenes", true)}
                    {horizontalTitle(
                        context.activeTheme === "dark" ? faSun : faMoon,
                        () => context.setActiveTheme(context.activeTheme === "dark" ? "light" : "dark"),
                        context.activeTheme === "dark" ? "Light theme" : "Dark theme"
                    )}
                </Container>

                <Container
                    display={{ index: "0", justify: "center", align: "center" }}
                    size={{ height: "37px", width: "calc(100vw - 30px)", margin: "-37px 0px 0px 10px" }}
                    color={{ backgroundColor: "c01" }}
                    border={{ border: { top: "1px solid" }, color: "c03", radius: "0px" }}
                />
            </Container>
        </Container>
    );
})`
    > .vertical {
        animation: slideVertical 0.5s ease-in;

        @keyframes slideVertical {
            from {
                transform: translateX(min(calc(1350px - 100vw), -100px));
            }
            to {
                transform: translateX(0px);
            }
        }

        @media screen and (max-width: 800px) {
            display: none;
        }
    }

    > .horizontal {
        animation: slideHorizontal 0.5s ease-in;

        @keyframes slideHorizontal {
            from {
                transform: translateY(50px);
            }
            to {
                transform: translateY(0px);
            }
        }

        @media screen and (min-width: 800px) {
            display: none;
        }
    }
`;

export default Menu;
